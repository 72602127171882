@import url('https://fonts.googleapis.com/css?family=IBM+Plex+Sans:400,500,600,700');

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  height: 100%;
}

body {
  min-height: 100%;
  padding-bottom: 48px;
  font-family: 'IBM Plex Sans', 'Roboto', 'Helvetica Neue', 'Arial', sans-serif;
  -webkit-font-smoothing: antialiased;
}

.player-wrapper {
  position: relative;
  padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
}

.responsive-image {
  width: 100%;
  max-width: 800px;
  height: auto;
} 

.view-comments{
  text-align: center;
  display: block;
  background-color: #d2dee8;
  padding: 10px 0;
  color: #000;
  text-decoration: none;
  color: #676767;
  font-weight: 600;
  margin-top: 10px;
}
.selectComment{
  margin-top:10px;
  padding:8px;
  color: #676767;
}
.navbar-brand{
  display: none; 
}
.comment-list-wrapper{
  margin-left:-2rem;
}
.sort-by-wrapper{
  padding-left: 2rem;
}
.list-comments-children{
  height: 0;
  overflow: hidden;
}
.continue-thread{
  font-size: 14px;
  margin-top: -5px;
  text-decoration: none;
  display: block;
}
.verify-link{
  padding: 0 2px;
}

.navbar-nav .nav-item a{
  font-size: 12px;
  color: #000;
}

@media (max-width: 1024px) {
  .navbar{
    width:100%
  } 
  .navbar-toggler:focus{
    box-shadow: none;
  }
  .navbar-brand{
    display: block;
    font-size: 13px;
  }
  .navbar-toggler{
    font-size: 13px;
  }
  .dropdown-menu{
    border:0;
    padding:0;
    margin:0;
  }
  .dropdown-menu .dropdown-item{
    padding:5px 0;
  }
  .navbar-collapse{
    background-color: #ffffff;
    padding: 10px;
    width: 100%;
  }
  .navbar-dark .navbar-collapse{
    background-color:#262626;
  } 
  .navbar-dark .dropdown-menu{
    background-color:#262626;
  }
  .navbar-dark .dropdown-menu .dropdown-item{
    color:#fff;
  }
}
